import React from "react";
import { Row, Col, Form, Button, Spinner, Container } from "react-bootstrap";
import { locationsByProvider, providerDisplayNames } from "../utils/LocationsList";
import { formatDate, timeOptions, dateOptions } from "../utils/Formatting";

type FinderSearchProps = {
    selectedDate: string,
    selectedTime: string,
    selectedLocation: string,
    onSelectDate: (selected: string) => void,
    onSelectTime: (selected: string) => void,
    onSelectLocation: (selected: HTMLCollectionOf<HTMLOptionElement>) => void,
    onSearch: () => void,
    isLoading: boolean
}

function FinderSearch({selectedDate, selectedTime, selectedLocation, onSelectDate, onSelectTime, onSelectLocation, onSearch, isLoading}: FinderSearchProps) {

    const locationOptions = () => {
        return Object.keys(locationsByProvider).map(provider => (
            <optgroup key={provider} label={providerDisplayNames[provider]}>
                {locationsByProvider[provider].map(([locationName, location]) => (
                    <option key={locationName} value={locationName} selected={locationName === selectedLocation} >
                        {location.displayName}
                    </option>
                ))}
                
            </optgroup>
        ));
    }

    return (
        <Container style={{ marginLeft: 0}} >
            <Row>
                <Col>
                <Form>
                    <Form.Group style={{marginTop: 10}}>
                        <Form.Label style={{ marginBottom: '2px' }}>Date</Form.Label>
                        <Form.Select onChange={({target}) => {onSelectDate(target.value)}} value={selectedDate}>
                            {dateOptions.map((d) => 
                                <option key={d.toLocaleString()} value={d.toISODate() as string}>
                                    {formatDate(d)}
                                </option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group style={{marginTop: 10}}>
                        <Form.Label style={{ marginBottom: '2px' }}>Time</Form.Label>
                        <Form.Select onChange={({target}) => {onSelectTime(target.value)}} value={selectedTime}>
                            {timeOptions.map((t) => 
                                <option key={t} value={t}>
                                   {t}
                                </option>)
                            }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group style={{marginTop: 10}}>
                        <Form.Label style={{ marginBottom: '2px' }}>Location</Form.Label>
                        <Form.Select onChange={({target}) => {onSelectLocation(target.selectedOptions)}}>
                            {locationOptions()}
                        </Form.Select>
                    </Form.Group>
                </Form>
                </Col>
            </Row>
            <Row style={{marginTop: 10, marginBottom: 10}}>
                <Col>
                <Button variant="primary" onClick={onSearch} >
                    {isLoading && <>
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        />
                        <span>Loading...</span>
                    </>
                    }
                    {
                      !isLoading && <span>Search</span>
                    }
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}

export default FinderSearch;
import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Finder from './components/Finder';
import OnboardingModal from './components/OnboardingModal';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  const availabilityEndpoint = `${process.env.REACT_APP_BACKEND_HOST}/api`;
  return (
    <Router>
      <Container className="d-flex flex-column min-vh-100 ml-2">
      <Helmet>
        <title>Squash Court Finder</title>
        <meta name="description" content="Easily find and book public squash courts in London. Check availability at local leisure centres and book a court today." />
        <meta name="keywords" content="Squash, Squash Court, Squash Court Finder, London, Leisure Centre, Book Squash Court, Squash Court Availability" />
      </Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "SportsActivityLocation",
          "name": "Squash Court Finder - London",
          "description": "A tool to find and book public squash courts in London.",
          "url": "https://www.squashfinder.co.uk",
          "image": "https://squashfinder.co.uk/favicon.ico"
        })}
      </script>
      <OnboardingModal/>
      <Routes>
        <Route path="*" element={<AppBody availabilityEndpoint={availabilityEndpoint} />} />
      </Routes>
    </Container>
    </Router>
  );
}

function AppBody({availabilityEndpoint}) {
  return (<>
  <Row>
  <Col className="mt-2">
    <h2>Squash Court Finder</h2>
    <h5>London</h5>
  </Col>
</Row>
<Row>
  <Col>
    <Finder availabilityEndpoint={availabilityEndpoint}/>
  </Col>
</Row>
<Row className="mt-auto mb-1"> {/* Use mt-auto to push the footer down */}
  <Col>
    <footer
      style={{
        fontSize: "0.7rem",
        backgroundColor: "#f8f9fa",
        textAlign: "center",
      }}
    >
      <p>
        Disclaimer: Squash Court Finder is not affiliated with or endorsed by any
        of the listed leisure centres. We provide information on squash court availability 
        based on publicly available data from their websites, and all bookings must be 
        completed directly on their platforms.
      </p>
      <p>
        Contact the developer:{" "}
        <a href="mailto:info@squashfinder.co.uk">info@squashfinder.co.uk</a>
      </p>
    </footer>
  </Col>
</Row>
  
  </>);
}

export default App;

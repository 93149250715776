import React, {useState, useEffect, useRef} from "react";
import { useLocation as useBrowserLocation } from "react-router-dom";
import FinderSearch from "./FinderSearch";
import FinderResponse from "./FinderResponse";
import {Locations} from "../utils/LocationsList";
import GetAvailabilities, { ApiResponseLocation } from "../utils/GetAvailabilities";
import { formatTime, nextHour, timeOptions, dateOptions } from "../utils/Formatting";
import { DateTime } from "luxon";
import { Col, Row, Spinner } from "react-bootstrap";
import { filterResponseForTime, findClosestTimeSlots, getSlotDiff, slotIsAvailable } from "../utils/Logic";


const useQuery = () => {
    return new URLSearchParams(useBrowserLocation().search);
};

const validateQuery = (query: URLSearchParams) => {
    const date = query.get("date");
    const time = query.get("time");
    const location = query.get("location");

    const validDate = date && dateOptions.map(d => d.toISODate()).includes(date);
    const validTime = time && timeOptions.includes(time);
    const validLocation = location && Object.keys(Locations).includes(location);

    return {
        date: validDate ? date : null,
        time: validTime ? time : null,
        location: validLocation ? location : null
    }
};

function Finder({availabilityEndpoint} : { availabilityEndpoint: string }) {
    const query = useQuery();

    const validatedQuery = validateQuery(query);

    const initialDate = validatedQuery["date"] || DateTime.now().toISODate() as string;
    const initialTime = validatedQuery["time"] || nextHour();
    const initialLocation = validatedQuery["location"] || "Balham";

    const [requestError, setRequestError] = useState<string|null>(null);
    const [date, setDate] = useState(initialDate as string);
    const [time, setTime] = useState(initialTime);
    const [location, setLocation] = useState<string>(initialLocation);
    const [searchLoading, setSearchLoading] = useState(false);
    const [response, setResponse] = useState<ApiResponseLocation[]|undefined>(undefined);
    const [altsLoading, setAltsLoading] = useState(false);
    const [altResponse, setAltResponse] = useState<ApiResponseLocation[]|undefined>(undefined);
    const [altRequestError, setAltRequestError] = useState<string|null>(null);
    const autoSearched = useRef(false);

    const SEARCH_TOLERANCE = 30; //minutes
    const ALT_SEARCH_TOLERANCE = 45; //minutes

    const dateHandler = (d: string) => {
        console.debug(`Setting date: ${d}`)
        setDate(d)
    }

    const timeHandler = (t: string) => {
        const formattedTime = formatTime(t)
        console.debug(`Setting time: ${formattedTime}`)
        setTime(formatTime(formattedTime))
    }
  
  
    const locationHandler = (selected: HTMLCollectionOf<HTMLOptionElement>) => {
      const selectedOptionsArray = [].slice.call(selected).map((item: HTMLOptionElement) => item.value)
      const location = selectedOptionsArray[0];
      console.debug(`Setting location ${JSON.stringify(location)}`)
      setLocation(prev => location ?? prev);
    };

    const searchHandler =  async () => {
        setSearchLoading(true);
        console.debug(`Searching with ${date}, ${time}, ${JSON.stringify(location)}`)
        setRequestError(null);
        setResponse(undefined);
        setAltResponse(undefined);
        const [apiResponse, apiError] = await GetAvailabilities(availabilityEndpoint, date, date, [location]);
        setRequestError(apiError);
        setResponse(apiResponse);
        setSearchLoading(false)
        await searchAlternatives(apiResponse, time);
        const newUrl = `?date=${date}&time=${time}&location=${location}`;
        window.history.pushState(null, '', newUrl);
    }; 

    const searchAlternatives = async (firstResponse: ApiResponseLocation[], searchTime: string) => {
        if (firstResponse[0] && firstResponse[0].dates[0]) {
            const availableSlots = firstResponse[0].dates[0].slots.filter((s) => slotIsAvailable(s))
            const responseClosestSlots = findClosestTimeSlots(availableSlots, searchTime)
            if (responseClosestSlots.closest) {
                console.log(`closest slot: ${JSON.stringify(responseClosestSlots.closest)} searchTime: ${searchTime} }`)
                const absDiff = Math.abs(getSlotDiff(responseClosestSlots.closest, searchTime))
                console.log(`diff is ${absDiff}`)
                if (absDiff > SEARCH_TOLERANCE) {
                    const altLocations = Locations[location].alternativeSites;
                    setAltsLoading(true);
                    const [apiResponse, apiError] = await GetAvailabilities(availabilityEndpoint, date, date, altLocations);
                    setAltsLoading(false);
                    setAltRequestError(apiError);
                    const filteredResponse = filterResponseForTime(apiResponse, searchTime, ALT_SEARCH_TOLERANCE)
                    setAltResponse(filteredResponse);
                }
            } else if (availableSlots.length === 0) {
                const altLocations = Locations[location].alternativeSites;
                setAltsLoading(true);
                const [apiResponse, apiError] = await GetAvailabilities(availabilityEndpoint, date, date, altLocations);
                setAltsLoading(false);
                setAltRequestError(apiError);
                const filteredResponse = filterResponseForTime(apiResponse, searchTime, ALT_SEARCH_TOLERANCE)
                setAltResponse(filteredResponse);
            }
        }
    }

    const hasSlots = (resp: ApiResponseLocation) => {
        if (resp.dates[0]) {
            return resp.dates[0].slots.length > 0;
        } else {
            return false;
        }
    }
    
    const altHasSlots = (resp: ApiResponseLocation[]) => !(resp.map(l => hasSlots(l)).every(b => b === false));

    const noCourtsMsg = `No courts available near your selected time.`

    useEffect(() => {
        if (validatedQuery["date"] && validatedQuery["time"] && validatedQuery["location"] && !autoSearched.current) {
            autoSearched.current = true;
            searchHandler();
        }
    });
    
    return (
        <>
            <Row className="flex-grow-1">
                <Col md={8}>
                    <FinderSearch
                        selectedDate={date}
                        selectedTime={time} 
                        selectedLocation={location}
                        onSelectDate={dateHandler} 
                        onSelectTime={timeHandler} 
                        onSelectLocation={locationHandler} 
                        onSearch={searchHandler}
                        isLoading={searchLoading}
                    />
                </Col>
            </Row>
            <Row>
                {!requestError && response && response[0] && (
                    <Col style={{ marginLeft: 20 }}>
                        <FinderResponse 
                            baseUrl={availabilityEndpoint} 
                            location={response[0].location} 
                            date={date} 
                            time={time} 
                            response={response[0]} 
                            isAlternative={false}
                        />
                    </Col>
                )}
                {requestError && <div className="p-2">{requestError}</div>}
            </Row>
            <Row>
                {altsLoading && (
                    <div className="p-2">
                        <p>{`${noCourtsMsg} Loading other options...`}</p>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                )}
            </Row>
            <Row>
                {!altsLoading && altResponse && altHasSlots(altResponse) && (
                    <div className="p-3">{`${noCourtsMsg} Other options nearby:`}</div>
                )}
            </Row>
            <Row>
                {!altsLoading && altResponse && !altHasSlots(altResponse) && (
                    <div className="p-3">{`${noCourtsMsg} No other options nearby.`}</div>
                )}
            </Row>
            <Row>
                {!altRequestError && altResponse && (
                    <Col>
                        {altResponse.map((apiResult) => (
                            hasSlots(apiResult) && (
                                <FinderResponse 
                                    baseUrl={availabilityEndpoint} 
                                    location={apiResult.location} 
                                    date={date} 
                                    time={time} 
                                    response={apiResult} 
                                    isAlternative={true}
                                />
                            )
                        ))}
                    </Col>
                )}
            </Row>
        </>
    );
}

export default Finder;